import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
// styles
import clsx from 'clsx';
import itemStyles from '../components/item/cases.module.css';
import styles from './condogenie.module.css';
// components
import Layout from '../components/item/layout';
import Team from '../components/team';
import Resume from '../components/resume';
import Blockquote from '../components/blockquote';
import VideoCase from '../components/video';
import DemoVideo from '../components/demo-video';
// other
import { meta } from '../../../metaData';

const CondogenieCase = () => {
  const data = useStaticQuery(graphql`
    query {
      rafalDyrda: file(relativePath: { eq: "rafal-dyrda.png" }) {
        childImageSharp {
          fluid(maxWidth: 430, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1: file(relativePath: { eq: "condogenie-screen-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "condogenie-screen-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "condogenie-screen-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "condogenie-screen-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "condogenie-screen-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      condogenie: file(relativePath: { eq: "career/condogenie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      video_cover: file(relativePath: { eq: "cover_condo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      score={['Proptech', 'Canada', 'January 2019', 'Finished']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="CondoGenie&nbsp;— multi-functioning platform for condominium management"
      subTitle="A sneak peek at how Brocoders developed a feature-rich product to help condo residents, condo board directors and property managers effectively cope with numerous condominium-related issues"
      metaData={meta.condogenie}
    >
      <DemoVideo source="condo" poster={data.video_cover.childImageSharp.fluid.srcWebp} />
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          The CondoGenie solution was designed to enhance condominium management, as well as to
          cover all the communication gaps within condominiums and its management companies.
          <br />
          <br />
          The solution supports an infinite number of users and provides a rich set of powerful
          tools that let all the interested parties stay and communicate directly on one platform,
          and acquire urgent assistance with any problems that might happen to their housing.
        </p>
        <h2 className={itemStyles.title}>Our client</h2>
        <p className={itemStyles.paragraph}>
          Rafal Dyrda is a Canadian entrepreneur, who decided to launch a GeniePad project back in
          2009 to be able to tackle the problems that his family faced while living in the
          condominium.
        </p>
        <p className={itemStyles.paragraph}>
          The Brocoders team built a highly dynamic and fruitful working environment with Rafal.
          Backed by our collaborative spirit, we managed to combine our efforts and create a
          client-oriented product that meets all the end-users' expectations.
        </p>
        <h2 className={itemStyles.title}>The situation</h2>
      </div>
      <Blockquote
        img={data.rafalDyrda.childImageSharp.fluid}
        text={`"One day we needed to get our door buzzer reprogrammed. This task took six months. Till then, we had to run down to let our visitors in. Soon after that, I decided to join the condo board. I was fed up with everything and wanted to see if I can make a difference.\n\nAfter being exposed to the problems first hand, I realized that the condo board and property manager didn't have the right tools to communicate with the residents. The condo board met once a month. That is when they reviewed all the letters from that correspondence mailbox. Then it took another month to make the decisions and reply to the resident.\n\nI had to put a stop to this."`}
        author={
          <React.Fragment>
            <strong>Rafal Dyrda</strong> <br />
            CEO and Founder <br />
            <a className={styles.link} href="https://www.condogenie.com" target="__blank">
              condogenie.com
            </a>
          </React.Fragment>
        }
      />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          Some years back, Rafal and his family decided to shift their place of residence and move
          to Canada. On November 30th, 2003, they finally received the keys to a new property, which
          was a two-bedroom condominium located in the heart of downtown Edmonton.
        </p>
        <p className={itemStyles.paragraph}>
          As Rafal says, everything was great for the first years, but then it turned out that he
          had no vaguest notion what to do next or what person to consult with in case of emerging
          problems. No matter what issues he faced, like an elevator that was out of service, a
          leaking roof or a flooded storage room, he had no idea who the condominium's property
          manager was.
        </p>
        <p className={itemStyles.paragraph}>
          He woke up to the fact that the condo board, along with the property manager, didn't have
          the appropriate tools to efficiently respond to requests or communicate with all the
          residents.
        </p>
        <p className={itemStyles.paragraph}>
          In light of this, backed by a substantial software development expertise, Rafal developed
          a tool for his condo board and manager to overcome those issues and get rid of the
          collaboration gap between all parties.
        </p>
        <p className={itemStyles.paragraph}>
          To his surprise, the tool started gaining traction, so Rafal morphed it into a Condominium
          Success System called GeniePad. The solution was mainly designed for the US and Canada
          markets.
        </p>
        <p className={itemStyles.paragraph}>
          Later on, he decided to become a member of the Canadian Condominium Institute in order to
          provide even more resources and information that were essential in multiple aspects of
          condominium living.
        </p>
        <p className={itemStyles.paragraph}>
          After a while, Rafal came up with a disruptive idea to reinvent the GeniePad platform to
          stay ahead of the curve, so that end-users could enjoy the best possible services. He
          wanted to update the system, create an intuitive, eye-catching design and implement new
          cutting-edge features.
        </p>
        <p className={itemStyles.paragraph}>
          To accomplish these challenging tasks, the client needed a seasoned team of senior-level
          experts with years of software development experience. Brocoders emerged as a natural
          choice to carry out the project.
        </p>
      </div>
      <Img
        fluid={data.screen1.childImageSharp.fluid}
        className={clsx(itemStyles.screen, itemStyles.screen1, styles.screenImg)}
        alt=""
      />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Working process</h2>
        <h3 className={itemStyles.subtitle}>Requirements elicitation</h3>
        <p className={itemStyles.paragraph}>
          Rafal reached out to our team back in 2018 to discuss all the details and further steps
          that we would stick to in the future. First and foremost, we started with video
          conferencing meetings in Zoom to get acquainted with the client's visions and
          expectations. Rafal required a complete rebranding of his product, including the brand
          name changing.
        </p>
        <p className={itemStyles.paragraph}>
          From the very beginning, we involved our in-house Business Analyst to ensure the
          completeness of the requirements elicitation, as well as to cover all the existing
          system's details and pitfalls.
        </p>
        <h3 className={itemStyles.subtitle}>Design</h3>
        <p className={itemStyles.paragraph}>
          Following one of the most crucial tasks set before us by the client, we had to develop a
          user-friendly design that wouldn't be much different from the previous one. It was
          necessary to do so since the vast majority of the GeniePad's end-customers were
          non-tech-savvy users who got used to an old interface.
        </p>
        <p className={itemStyles.paragraph}>
          We created a few prototypes and demonstrated these to Rafal in order to acquire the
          feedback of his end-customers. After the requirements elicitation, Brocoders went with the
          Scrum methodology to make the development process more transparent to the client all along
          the way. We used to provide Rafal with small increments on a regular basis to get relevant
          feedback.
        </p>
      </div>
      <Img fluid={data.screen4.childImageSharp.fluid} className={itemStyles.screen} alt="" />
      <div className={itemStyles.inner}>
        <h3 className={itemStyles.subtitle}>Development</h3>
        <p className={itemStyles.paragraph}>
          Aside from the user-centric design that would allow for smooth surfing through the
          web-based platform, we also had to ensure the system would be easy-to-maintain after the
          release date. As for the technology, we opted for the ActiveAdmin framework, React for the
          frontend and Ruby on Rails for the backend.
        </p>
        <p className={itemStyles.paragraph}>
          The development process was set up in accordance with the existing system, yet we decided
          to create a new product from scratch to ensure the platform would be anchored by
          leading-edge tech trends.
        </p>
        <p className={itemStyles.paragraph}>
          Since the CondoGenie platform was primarily designed to enable an adequate level of
          collaboration and communication, we were tasked to implement the features as follows:{' '}
        </p>
        <ul className={itemStyles.bullets}>
          <li>News posting</li>
          <li>Vital documentation posting</li>
          <li>Tasks creation and management</li>
          <li>Calendar events creation and management</li>
          <li>Amenity bookings</li>
          <li>In-built emergency SMS-messaging</li>
        </ul>
        <p className={itemStyles.paragraph}>
          To outline roles among the final product's users, we divided the system into the three
          main modules:
        </p>
        <div>
          <div className={styles.card}>
            <div className={clsx(styles.cardIcon, styles.cardIcon1)} />
            <div className={styles.cardContent}>
              <h4 className={styles.cardTitle}>The Account is a condominium</h4>
              <p className={itemStyles.paragraph}>
                Strictly speaking, it's a house or several houses that are connected under one
                account. For example, Brocoders have two offices, so if we register in the
                CondoGenie system, then we would have one account called Brocoders, and the two
                buildings: Baranovka and Prokofieva. Each condominium can register in the system on
                its own.
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <div className={clsx(styles.cardIcon, styles.cardIcon2)} />
            <div className={styles.cardContent}>
              <h4 className={styles.cardTitle}>The Management Company</h4>
              <p className={itemStyles.paragraph}>
                Is a property management company that manages a bunch of accounts at once. Unlike
                the Account module, the Management Company module can be added to the system only by
                a Super Admin.
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <div className={clsx(styles.cardIcon, styles.cardIcon3)} />
            <div className={styles.cardContent}>
              <h4 className={styles.cardTitle}>The Super Admin Panel</h4>
              <p className={itemStyles.paragraph}>Was designed exclusively for Rafal Dyrda.</p>
            </div>
          </div>
        </div>
        <p className={itemStyles.paragraph}>
          We used to provide Rafal with a demo each time a module was finished and ready for
          testing. In turn, Rafal showcased the demo to the end-users so that we could acquire
          first-hand feedback and follow the exact requests from the targeted audience. Given the
          size of the Account module, we had to provide a few demos during this stage.
        </p>

        <VideoCase image={data.condogenie.childImageSharp.fluid} source="condogenie" />

        <Team
          color="#82AA00"
          title="Our team"
          list={[
            { count: 1, title: 'Project\nmanager' },
            { count: 1, title: 'Business\nAnalyst' },
            { count: 1, title: 'Product\ndesigner' },
            { count: 1, title: 'QA\nEngineer' },
            { count: 3, title: 'Backend\nEngineers' },
            { count: 3, title: 'Frontend\nEngineers' },
            { count: 1, title: 'One\nDevOps' },
          ]}
        />

        <Resume services={['product', 'dedicatedTeam']} technology={['react', 'rails', 'figma']} />
      </div>

      <Blockquote
        img={data.rafalDyrda.childImageSharp.fluid}
        isRating
        text={
          '"I had very great communication with the team.\n \n Upon launching the project, we were able to increase client satisfaction and provide our clients what they were asking for, improve their workflow as well as make the software more powerful and easier to use than the previous software that was developed."'
        }
        downloadReviewLink="https://hubs.ly/Q01qgRMZ0"
        author={
          <React.Fragment>
            <strong>Rafal Dyrda</strong> <br />
            CEO and Founder <br />
            <a className={styles.link} href="https://www.condogenie.com" target="__blank">
              condogenie.com
            </a>
          </React.Fragment>
        }
      />
      <Img fluid={data.screen2.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Business Challenges</h2>
        <p className={itemStyles.paragraph}>
          With a transparent web development process, the Brocoders team managed to cope with
          multiple issues on a hassle-free basis. Nevertheless, handling all parts of such a
          large-scale project was always going to be a challenging task. That said, we had to employ
          high-end approaches to solve several problems that we came up against.
        </p>
        <p className={itemStyles.paragraph}>
          To ensure rock-solid security of in-app payments, we opted for the three out-of-the-box
          payment systems: Stripe, Rotesso, Xero. Such a business strategy allowed for reducing the
          development time and costs since we didn’t have to code this part from scratch. However,
          when it came to the integration stage, we had to fix numerous bugs and close different
          gaps to enable a smooth operation of the entire system.
        </p>
        <p className={itemStyles.paragraph}>
          Once all the development phases were a long way behind, our further collaboration with the
          client was aimed at migrating the existing users’ data to a new platform. Again, we faced
          numerous bugs and data loss that we managed to fix or restore under tight timelines.
        </p>
        <p className={itemStyles.paragraph}>
          Thanks to a fruitful collaboration with Rafal, the end-users kept providing us with their
          invaluable feedback, thus extremely reducing the time that we spent on fixing the bugs.
        </p>
      </div>
      <Img fluid={data.screen5.childImageSharp.fluid} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Delivered Solution</h2>
        <p className={itemStyles.paragraph}>
          The solution's development has been going on for 18 months. During this period, the
          Brocoders team proved the ability to smoothly handle large-scale international projects.
        </p>
        <p className={itemStyles.paragraph}>
          The resulting web product is a next-gen platform that comes with a host of collaboration
          and communication features that users in Condominiums, Stratas, HOAs and Co-Ops would love
          to enjoy. The interface is intuitive and remarkably easy to navigate just as the client
          had asked for.
        </p>
        <p className={itemStyles.paragraph}>
          CondoGenie helps users to post events and news that take place in a condominium to keep
          everybody informed about condo life as well as the documents that are important for
          residents.
        </p>
        <p className={itemStyles.paragraph}>
          There are two types of News Announcements: users can either post information that can't be
          commented on or utilize the message forum feature where they can leave their comments.
        </p>
        <p className={itemStyles.paragraph}>
          Users can create tasks within the system, in case they have such a permission. That said,
          all the users can ask their board team directly to deal with any issue while keeping all
          the tasks in one place.
        </p>
        <p className={itemStyles.paragraph}>
          An admin can view a report about his residents. The report contains various info, such as
          phone number, cell number, email, pets (type of pet), vehicle parking, last visit and so
          on. CondoGenie can cover a user's need to buy or sell anything inside of a condo within
          the account, whether that be a chair, TV, etc.
        </p>
        <p className={itemStyles.paragraph}>
          All users can acquire relevant info on the condo they are looking for thanks to a public
          website.
        </p>
      </div>
      <Img fluid={data.screen3.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={clsx(itemStyles.lastItem, itemStyles.inner)}>
        <h2 className={itemStyles.title}>Future plans</h2>
        <p className={itemStyles.paragraph}>
          By collaborating with Brocoders, the client received a much-enhanced innovative platform
          designed to make the condominiums lives easier and more comfortable. To date, we've
          managed to accomplish all the development milestones.
        </p>
        <p className={itemStyles.paragraph}>
          There are 254 Accounts and 20 Management Companies registered in the system, resulting in
          more than 60.000 active users. Currently, we are migrating the users' data to a new
          platform.
        </p>
        <p className={itemStyles.paragraph}>
          In fact, our work is underway to further improve the solution and add new features, like
          separate functionality for the Management Company module.
        </p>
      </div>
    </Layout>
  );
};

export default CondogenieCase;
